<template>
  <v-toolbar app color="primary" dark>
    <v-toolbar-title>Chi Trip Planner</v-toolbar-title>
    <v-btn variant="text" @click="changeLanguage('English')"
      :class="{ 'active': language === 'English', 'muted': language !== 'English' }" size="x-small">
      EN
    </v-btn>
    |
    <v-btn variant="text" @click="changeLanguage('Chinese')"
      :class="{ 'active': language === 'Chinese', 'muted': language !== 'Chinese' }" size="x-small">
      CN
    </v-btn>
  </v-toolbar>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-form @submit.prevent="submitForm">
          <v-select v-model="selectedInterests" :items="interests" label="Select Interests" multiple dense chips
            max-height="200px"></v-select>
          <v-text-field v-model="destination" :rules="rules" label="Destination" dense></v-text-field>
          <v-text-field v-model="duration" label="Duration"></v-text-field>
          <v-text-field v-model="token" v-if="devmode" label="Token"></v-text-field>
          <v-btn type="submit" :disabled="isLoading" color="primary">Submit</v-btn>
        </v-form>
      </v-card-text>
      <div v-if="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </v-card>

    <v-divider v-if="response && !isLoading"></v-divider>

    <v-card>
      <v-card-text class="response" v-if="response && !isLoading" style="white-space: pre-wrap;">
        {{ response }}
      </v-card-text>
      <v-alert v-if="error && !isLoading" type="error" dismissible @dismiss="error = null">
        {{ error.message }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default ({
  data() {
    return {
      selectedInterests: ['Culture', 'Nature', 'Food'],
      destination: 'Melbourne, Australia',
      language: 'English',
      languages: ['English', 'Chinese'],
      token: '',
      rules: [
        value => {
          if (value) return true

          return 'You must enter a destination.'
        },
      ],
      duration: '1 Day',
      interests: ['Culture', 'Nature', 'Food'],
      isLoading: false,
      response: '',
      error: null,
      drawer: null,
    };
  },
  computed: {
    devmode: {
      get() {
        const urlParams = new URLSearchParams(window.location.search);
        return Boolean(urlParams.get('devmode'));
      },
    }
  },
  methods: {
    changeLanguage(language) {
      this.language = language;
    },
    submitForm() {
      const token = this.token;
      const tripData = {
        id: uuidv4(),
        interests: this.selectedInterests,
        destination: this.destination,
        duration: this.duration,
        language: this.language,
      };
      this.error = null;
      this.isLoading = true;
      fetch('https://trip-advisor-server.chi-trip.workers.dev/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify(tripData)
      })
        .then(response => response.text())
        .then(data => {
          console.log(data);
          this.response = data;
        })
        .catch(error => {
          this.error = error;
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
  },
});
</script>

<style>
.v-btn.active {
  color: white;
}

.v-btn.muted {
  color: grey;
}

/* CSS reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  font-family: Arial, sans-serif;
}
</style>